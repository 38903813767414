import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "Slider",
  code: "Slider"
};
export const _frontmatter = {
  "menuLabel": "Slider",
  "sortOrder": 19.4
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LivePlayround = makeShortcode("LivePlayround");
const Args = makeShortcode("Args");
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Slider`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-slider--default-story" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Slider } from "@caesars-digital/caesars-ui"
`}</code></pre>
    <p>{`Sliders allow users to select a value within a specified range by dragging a handle along a track. This component is particularly useful for adjusting settings, selecting a range, or manipulating numerical values in a visual and interactive manner.`}</p>
    <p>{`Sliders are ideal for tasks that require quick adjustments and immediate feedback, such as setting volume, brightness, or price filters. When designing sliders, it's important to define a clear minimum and maximum value, ensuring users understand the range they can select from. Visual cues such as labels and markers can enhance usability by indicating specific values and providing context. Sliders should offer smooth and responsive interactions, with a handle that is easy to drag on both desktop and mobile devices.`}</p>
    <hr></hr>
    <Args component={componentName} className="mb-20" mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
    <AccessibilityTable.Principle principle="Percievable" status="ready">
        Includes labels and markers to indicate the range and current value and provides dynamic visual feedback as the handle is dragged.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Operable" status="ready">
        The slider handle can be easily dragged with a mouse or touched on mobile devices. It supports keyboard navigation for adjusting the slider value. Provides large enough touch targets to facilitate easy interaction.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Understandable" status="ready">
        Uses clear and concise labels for the minimum, maximum, and current values when provided. Provides consistent behavior for slider interaction across different contexts.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Robust" status="ready">
        Ensures compatibility with various browsers and devices. Uses semantic HTML and ARIA roles for the slider component.
    </AccessibilityTable.Principle>
    </AccessibilityTable>
    

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      